"use client";
import dynamic from "next/dynamic";
import { useState, Fragment } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { useRouter } from "next/router";
import Image from "next/image";
import { useWindowDimensions } from "../../utils";
import Link from "next/link";
import AnchorLinkLanguage from "../anchor/AnchorLanguage";

const AnchorLink = dynamic(() => import("../anchor/Anchor"), {
  ssr: false,
});
const MainMenu = dynamic(() => import("./menu/MainMenu"), {
  ssr: false,
});
const Logo = dynamic(() => import("./Logo"), {
  ssr: false,
});
const Search = dynamic(() => import("./Search"), {
  ssr: false,
});
const MobileMenu = dynamic(() => import("./MobileMenu"), {
  ssr: false,
});

const CustomNavbar = ({ menu, allMenu }) => {
  // Locale
  const { width } = useWindowDimensions();
  const { locale } = useRouter();
  const router = useRouter();
  const revLocale = locale === "en" ? "tr" : "en";
  const lang = locale === "en" ? 2 : 1;
  //Mobile Menu States
  const [menu_mobile, setMenuMobile] = useState(false);
  const [menu_mobile_child_act, setMenuMobileChildAct] = useState(false);
  //Mobile Search States
  const [search_mobile, setSearchMobile] = useState(false);
  const [search_desktop, setSearchDesktop] = useState(false);

  const handlSearchOpen = () => {
    setSearchMobile(!search_mobile);
    setMenuMobile(false);
    setMenuMobileChildAct(false);
  };

  return (
    <>
      <header className="header" style={{ paddingTop: "22px" }}>
        <Container>
          <div className="header_desktop">
            <div className="d-flex justify-content-between align-items-center">
              {/* İŞNET LOGO */}
              <Logo />
              <div className="">
                <div
                  className={`menu position-relative d-inline-block text-left`}
                >
                  {menu?.length > 0 &&
                    menu?.map((item, index) => (
                      <Fragment key={index}>
                        {item.parentId == null &&
                        lang === item.languageId &&
                        !item.isDeleted &&
                        item.inHeader &&
                        !item.inFooter ? (
                          <>
                            <MainMenu key={index} item={item} index={index} />
                          </>
                        ) : null}
                      </Fragment>
                    ))}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <Link
                  href="https://dijitalhizmet.isnet.net.tr/invoice-payment"
                  target="_blank"
                  className="btn btn-outline-default btn-online"
                  style={{
                    borderWidth: "2px",
                    fontSize: "14px",
                    marginRight: "6px",
                    display: width > 1250 ? "flex" : "hidden",
                  }}
                >
                  {lang === 1 ? "Fatura Öde" : "Bill Payment"}
                </Link>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-default"
                    style={{ padding: "0.45rem", borderWidth: "2px" }}
                  >
                    {lang === 1 ? "   ONLİNE İŞLEMLER" : "Online Transactions"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="https://dijitalhizmet.isnet.net.tr"
                      target="_blank"
                    >
                      {lang === 1
                        ? "   ONLİNE İŞLEMLER"
                        : "Online Transactions"}
                    </Dropdown.Item>
                    {width < 1250 && (
                      <Dropdown.Item
                        href="https://dijitalhizmet.isnet.net.tr/invoice-payment"
                        target="_blank"
                      >
                        {lang === 1 ? "Fatura Öde" : "Bill Payment"}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      href="https://webmail.isnet.net.tr"
                      target="_blank"
                    >
                      WEBMAIL
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div
                  className={`menu-right  menu_right`}
                  style={{ fontSize: "22px" }}
                  onClick={() => setSearchDesktop(!search_desktop)}
                >
                  <i className="icon-search1" />
                </div>
                {router.asPath.split("/")[1] === "blog" ||
                router.asPath.split("/")[1] === "blogicerik" ? null : (
                  <AnchorLinkLanguage
                    href={`/${router.asPath}`}
                    locale={revLocale}
                    className="btn ms-2"
                    style={{ padding: "0 10px" }}
                  >
                    {revLocale.toUpperCase()}
                  </AnchorLinkLanguage>
                )}
              </div>
            </div>
          </div>
        </Container>
        <div className="header_mobile header-mobile">
          <div className="float-start">
            <AnchorLink href="/" className="header_logo_mobile">
              <Image
                width={173}
                height={39}
                src="/yirmibeslogo.png"
                alt="işnet"
                unoptimized
              />
            </AnchorLink>
          </div>
          <div className="float-end">
            <AnchorLinkLanguage
              href={`/${router.asPath}`}
              locale={revLocale}
              style={{ color: "#6c757d", width: "auto", padding: 0 }}
            >
              {revLocale.toUpperCase()}
            </AnchorLinkLanguage>
            <a className="search" onClick={handlSearchOpen}>
              <i className="icon-search1" />
            </a>
            <a
              className={`${
                menu_mobile ? " mobile_menu mobile_menu_active " : "mobile_menu"
              }`}
              onClick={() => {
                setMenuMobile(!menu_mobile);
                setSearchMobile(false);
              }}
            >
              <span></span>
            </a>
          </div>
          {menu_mobile ? (
            <>
              <div
                className={`${
                  menu_mobile
                    ? "mobile_menu_content mobile_menu_active "
                    : "mobile_menu "
                } header-mobile-menu`}
              >
                <MobileMenu allMenu={allMenu} setMenuMobile={setMenuMobile} />
                <div className="mobile_menu_btns">
                  <a
                    href="https://dijitalhizmet.isnet.net.tr"
                    className="btn btn-default ml-5"
                    style={{ color: "#FFF !important" }}
                    target="_blank"
                  >
                    {lang === 1 ? "   ONLİNE İŞLEMLER" : "Online Transactions"}
                  </a>
                  <a
                    href="https://dijitalhizmet.isnet.net.tr/invoice-payment"
                    target="_blank"
                    className="btn btn-default ml-5"
                    style={{ color: "#FFF !important" }}
                  >
                    {lang === 1 ? "Fatura Öde" : "Bill Payment"}
                  </a>
                  <a
                    href="https://webmail.isnet.net.tr/"
                    className="btn btn-default ml-5"
                    style={{ color: "#FFF !important" }}
                    target="_blank"
                  >
                    WEBMAIL
                  </a>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {search_mobile ? (
            <div className=" mobile_search">
              <Search />
            </div>
          ) : (
            ""
          )}
        </div>
      </header>
      {search_desktop && <Search />}
    </>
  );
};

export default CustomNavbar;
